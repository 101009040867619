


































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import NotesTodoMixin from '@/mixins/notes-todo-mixin';
import { Component } from 'vue-property-decorator';
import PrintOptions from '../prints/PrintOptions.vue';

@Component({
  mixins: [NotesTodoMixin],
  components: {
    BaseToolbar,
    PrintOptions
  }
})
export default class NotesTodoToolbar extends BaseToolbar {
  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;

  $refs!: {
    printOptions: PrintOptions,
  };

  get localNotesTodoComponent(): any {
    return this.currentPageInstance || {};
  }

  get toggleEditMode() {
    return this.localNotesTodoComponent.toggleEditMode || this.emptyFunction;
  }

  get addReminder() {
    return (
      this.localNotesTodoComponent.addReminder ||
      ((type: string) => {
        type;
      })
    );
  }

  get multipleDelete() {
    return this.localNotesTodoComponent.multipleDelete || this.emptyFunction;
  }
}
