var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-toolbar", {
    attrs: { searchable: "", search: _vm.searchText },
    on: {
      "update:search": function($event) {
        _vm.searchText = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "toolbar-left",
        fn: function() {
          return [
            _c(
              "v-col",
              { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
              [
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    clearable: "",
                    "single-line": "",
                    "hide-details": "",
                    "prepend-inner-icon": "fal fa-search",
                    label: _vm.$t("searchLabel")
                  },
                  model: {
                    value: _vm.searchText,
                    callback: function($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "toolbar-right",
        fn: function() {
          return [
            !_vm.editMode
              ? _c(
                  "v-menu",
                  {
                    attrs: {
                      transition: "scroll-y-transition",
                      "offset-y": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 my-1",
                                    attrs: {
                                      color: "primary",
                                      label: _vm.$t("addLabel"),
                                      text: "",
                                      outlined: ""
                                    }
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("fal fa-plus")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3103583750
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addReminder("TD")
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "drop-down-list" },
                              [_vm._v(_vm._s(_vm.$t("addTodoDropdownLabel")))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addReminder("NT")
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "drop-down-list" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("addNotesDropdownLabel")) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : [
                  _vm.hasSelectedRows
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "ml-2 my-1",
                          attrs: {
                            color: "error",
                            label: _vm.$t("deleteLabel"),
                            text: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.multipleDelete.apply(null, arguments)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "pb-btn",
                    {
                      staticClass: "ml-2 my-1",
                      attrs: {
                        color: "primary",
                        label: _vm.$t("doneLabel"),
                        text: "",
                        outlined: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleEditMode.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "done-icon" }, [
                        _vm._v("fal fa-pen-slash")
                      ])
                    ],
                    1
                  )
                ],
            !_vm.editMode
              ? _c(
                  "v-menu",
                  {
                    attrs: { bottom: "", "offset-y": "", "close-on-click": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var onMenu = ref.on
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 my-1",
                                    attrs: {
                                      color: "primary",
                                      label: _vm.$t("actionsLabel"),
                                      text: "",
                                      outlined: ""
                                    }
                                  },
                                  Object.assign({}, onMenu)
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("fal fa-ellipsis-stroke-vertical")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      648777904
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.toggleEditMode } },
                          [
                            _c(
                              "v-btn",
                              { attrs: { text: "", color: "primary" } },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-pen-to-square")
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("manageLabel")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "pb-btn",
              {
                staticClass: "ml-2 my-1 d-inline-block",
                attrs: {
                  id: "print",
                  color: "primary",
                  label: _vm.$t("sendToLabel"),
                  text: "",
                  outlined: ""
                },
                on: {
                  click: function($event) {
                    return _vm.showPrintOptions(_vm.$refs.printOptions, "Notes")
                  }
                }
              },
              [_c("v-icon", [_vm._v("fal fa-arrow-up-from-square")])],
              1
            ),
            _c("print-options", { ref: "printOptions" })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }